/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdate = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdateDelayed = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'flex';
  }, 1);
};
